<template>
	<div class=" container-wapper" v-if="pageLoadingComplete">
		<div class="background-white">
			<commonHeader :show-dialogue="true" :customer="goodsData.customer.url" />
			<div class="card" @click="toAddressEdit()" v-if="goodsData.express === 1">
				<div class="card-content ">
					<div class="address container">
						<div class="address-content">
							<div class="address-edit" v-if="addrList.length > 0">
								<p class="name">
									<span>{{ currentAddress.consignee }}</span><span>{{ currentAddress.phone }}</span>
								</p>
								<p class="text">
									{{ currentAddress.text }}
									{{ currentAddress.address }}
								</p>
							</div>
							<div class="address-add" v-else>
								<p class="name">
									<!--                <img :src="getCurrentBrand.imageGoddsLocal" class="icon"/>-->
									<span>请填写收货人信息</span>
								</p>
							</div>
						</div>
						<van-icon name="arrow" />
					</div>
				</div>
			</div>

			<div class="card ">
				<div class="card-title">商品信息</div>
				<div class="card-content ">
					<ul class="infor-list container">
						<li>
							<div class="infor-list-item">
								<p class="name">
									<span class="text">{{ goodsData.title }}</span>
								</p>
								<p class="detail">
									<span class="text">￥{{ goodsData.cost_payment }}</span>
									<span class="text">数量：1</span>
								</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="card">
				<div class="card-content ">
					<van-cell-group class="ksii-group container" :border="false">
						<van-cell title="商品金额" :value="'￥' + clearPriceZero(goodsData.amount)" :border="false" />
						<!-- <van-cell
            title="优惠券"
            value="有可用"
            :border="false"
            is-link
            v-if="false"
          >
          </van-cell> -->
						<!--          <van-cell-->
						<!--              title="代金券"-->
						<!--              v-if="total.cost_coupon !== '0' && total.cost_coupon !== '0.00'"-->
						<!--              :value="'-￥' + clearPriceZero(total.cost_coupon)"-->
						<!--              :border="false"-->
						<!--          />-->
						<!--          <van-cell-->
						<!--              title="余额支付"-->
						<!--              v-if="total.cost_balance !== '0.00'"-->
						<!--              :value="'-￥' + clearPriceZero(total.cost_balance)"-->
						<!--              :border="false"-->
						<!--          />-->
						<van-cell title="合计" :border="false">
							<template #right-icon>
								<div class="price">
									<span class="symbol">￥</span>
									<span class="number">{{
											clearPriceZero(goodsData.cost_payment)
									}}</span>
								</div>
							</template>
						</van-cell>
					</van-cell-group>
				</div>
			</div>

			<div class="card">
				<div class="card-title">说明</div>
				<div class="card-content ">
					<div class="explain container">
						<p class="explain-text">
							1.拼团商品不支持余额支付，需使用微信或支付宝直接支付购买
						</p>
						<p class="explain-text"></p>
					</div>
				</div>
			</div>
		</div>
		<!--底部按钮-->
		<footerFixed>
			<pageBottomButton color="red" buttonType="text" text="提交订单" @click="buy" :disabled="isDisableButton" />
		</footerFixed>
	</div>
</template>

<script>
import {
	orderCreatePayment,
	apiGetOrderPrepare,
	apiQueryPaymentResult,
} from '@/api/order'
import { apiAddressList } from '@/api/address'

import pageBottomButton from '@/components/page-bottom-button'
import footerFixed from '@/components/page-footer-fixed'
import commonMixins from "@/mixins/common";
import { updateAppMeta } from '@/utils/appDevice'


export default {
	components: {
		pageBottomButton,
		footerFixed,
	},
	mixins: [commonMixins],
	data() {
		return {
			no: '', //订单信息
			currentContact: {
				name: '请填写收货人信息',
			},
			// 地址列表
			addrList: [],

			goodsData: {},

			selectAddressId: this.$store.state.address.select_id,

			pageLoadingComplete: false,

			paymentSuccessURL: '', // 用户支付成功后的跳转地址
			isDisableButton: false, // 是否禁用按钮
			resultSelectTimer: false, // 结果轮询定时器
		}
	},
	computed: {
		clearPriceZero() {
			return (number) => this.$util.clearPriceZero(number.toString())
		},
		currentAddress() {
			let o = null,
				selectAddressId = this.selectAddressId,
				addrList = this.addrList
			addrList.forEach((item) => {
				if (item.id === selectAddressId) {
					o = item
				}
			})
			return o || addrList[0]
		},
		buttonText() {
			let n = this.clearPriceZero(this.goodsData.cost_payment)
			return `支付￥${n}`
		},
	},
	mounted() {
		this.no = this.$route.query.no
		this.getAddressList()
		this.getOrderPrepare()
		this.$util.setShare()

		// 更新app meta信息
		updateAppMeta()
	},
	methods: {
		// 获取地址列表
		getAddressList() {
			apiAddressList({}).then((res) => {
				this.addrList = res.data
			})
		},

		// 获取交易信息
		getOrderPrepare() {
			let no = this.no
			apiGetOrderPrepare({ no })
				.then((res) => {
					// 请求成功
					if (res.status === 1) {
						this.goodsData = res?.data
						this.pageLoadingComplete = true
					}
				})
				.catch(() => {
					this.pageLoadingComplete = true
				})
		},

		// 生成创建订单请求参数
		getOrderCreateParam() {
			// 支付回调地址
			let paymentCallURL = this.getPaymentCallURL()
			// 请求公共参数
			let param = {}
			param.address = this.currentAddress
			// 支付失败回调地址
			param.quitUrl = paymentCallURL
			// 支付成功回调地址
			param.returnUrl = paymentCallURL

			return param
		},

		// 订单创建请求
		createOrder() {
			let param = this.getOrderCreateParam()
			if (this.goodsData.express === 1 && !param.address) {
				this.$toast('请填写收货地址')
				return
			}

			param.no = this.no
			this.isDisableButton = true

			orderCreatePayment(param)
				.then((res) => {
					setTimeout(() => {
						this.isDisableButton = false
					}, 5000)

					// 请求失败时返回给出相应提示
					if (res.status !== 1) {
						this.$toast(res?.message)
						return
					}
					this.$util.locationHref(res.data.url)
				})
				.catch(() => {
					this.$toast('服务器忙，请稍后重试')
					this.isDisableButton = false
				})
		},

		// 获取支付后回调地址
		getPaymentCallURL() {
			return window.location.origin + '/wap/' + 'payment/result?no=' + this.no
		},

		// 执行购买成功方法
		executeBuySuccessFn(id) {
			window.location.replace(this.$util.getGoodsDetailURL({ id }))
		},

		// 进行购买
		buy() {
			let no = this.no
			apiQueryPaymentResult({ no }).then((res) => {
				let resData = res?.data

				// 0:未支付 1:已支付 2:退款中 3:退款成功
				let order_status = resData.order_status

				// 未支付时，创建支付订单
				if (order_status === 0) {
					this.createOrder()
					return
				}
				this.executeBuySuccessFn(resData.id)
			})
		},

		// 跳转地址编辑
		toAddressEdit() {
			this.$router.push({ path: '/address/list' })
		},
	},
}
</script>

<style lang="scss" scoped>
.van-cell {
	padding-left: 0;
	padding-right: 0;
}

.card {
	background-color: #fff;
	overflow: hidden;

	&+& {
		border-top: 0.32rem solid #f7f8fa;
	}

	&-title {
		font-size: 0.43rem;
		line-height: 1;
		color: #333333;
		margin-top: 0.43rem;
		font-weight: bold;
		padding: 0 $containerPadding;
	}
}

.infor {
	&-list {
		>li+li {
			border-top: 1px solid #ebecf0;
		}

		&-item {
			padding: 0.5rem 0;

			.name {
				color: #333333;
				font-size: 0;
				margin-bottom: 0.2rem;

				>span {
					vertical-align: middle;
				}

				.lable {
					float: left;
					margin-top: 0.05rem;
					display: block;
					padding: 0 0.1rem;
					height: 0.45rem;
					line-height: 0.45rem;
					font-size: 0.29rem;
					border-radius: 0.05rem;
					background: #8f97a8;
					color: #fff;
					margin-right: 0.2rem;
				}

				.text {
					font-size: 0.4rem;
					line-height: 0.56rem;
				}
			}

			.detail {
				.text {
					margin-right: 0.45rem;
					display: inline-block;
					font-size: 0.32rem;
					line-height: 0.45rem;
					color: #919499;
				}
			}
		}
	}
}

.ksii-group {
	padding-top: 0.2rem;
	padding-bottom: 0.2rem;

	.price {
		font-size: 0.53rem;
		font-weight: 500;
		color: #ff6a4d;

		.symbol {
			font-size: 0.37rem;
		}

		.number {
			font-size: 0.6rem;
		}
	}
}

.explain {
	padding-top: 0.45rem;
	padding-bottom: 0.3rem;

	&-text {
		font-size: 0.35rem;
		line-height: 0.48rem;
		color: #919499;

		&+& {
			margin-top: 0.43rem;
		}
	}
}

.play-button {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	z-index: 1;
	padding-top: 0.43rem;
	padding-bottom: 0.6rem;
	@include maxWidth();

	.button {
		width: 100%;
	}
}

.address {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 2rem;
	position: relative;
	background-color: #fff;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 3%;
		background-image: url(../../../assets/images/goods/play_line.png);
		background-size: 100%;
		width: 94%;
		height: 0.05rem;
		background-repeat: no-repeat;
	}

	.van-icon-arrow {
		font-size: 0.4rem;
		color: #c2c5cc;
	}

	&-content {
		max-width: 6.8rem;
	}

	&-edit {
		padding: 0.4rem 0;

		.name {
			font-size: 0.43rem;
			font-weight: bold;
			color: #18191a;
			margin-bottom: 0.3rem;

			>span+span {
				margin-left: 0.45rem;
			}
		}

		.text {
			font-size: 0.32rem;
			line-height: 0.45rem;
			color: #333333;
		}
	}

	&-add {
		.name {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-size: 0.4rem;

			.icon {
				width: 0.4rem;
				margin-right: 0.2rem;
			}
		}
	}
}
</style>
